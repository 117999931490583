import {motion} from 'framer-motion';
import NumberCounter from 'number-counter';
import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import '../../App.css';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';;

export default function Hero() {
  const transition={type:'spring',duration:3}
  return (
    <div className='hero'>
      <div className="blur hero-blur"></div>
      <div className="left_h">
        <Header/>
        <div className="the-best-ad">
          <motion.div
          initial={{left:'238px'}}
          whileInView={{left:'8px'}}
          transition={{...transition,type:'tween'}}
          ></motion.div>
          <span>the best fittness club in the town</span>
        </div>


        <div className="hero-text">
           <div>
            <span>Shape </span>
            <span>Your</span>
           </div>
           <div>
            <span>Ideal </span>
            <span>Body</span>
           </div>
           <div className="span">
            In here we will help you to shape and build your ideal body and live up your life to fullest 
           </div>
        </div>
        <div className="figures">
          <div>
            <span><NumberCounter end={140} start={100} delay='4' preFix="+"/></span>
            <span>expert coaches</span>
          </div>
          <div>
            <span><NumberCounter end={9508} start={9000} delay='4' preFix="+"/></span>
            <span>members joined</span>
          </div>
          <div>
            <span><NumberCounter end={50} start={25} delay='4' preFix="+"/></span>
            <span>fitness programs</span>
          </div>

        </div>
          <div className="hero-button">
            <button className="btn">Get Started</button>
            <button className="btn">Learn More</button>
          </div>
      </div>
      <div className="right_h">
        <button className="btn">Join Now</button>
        <motion.div initial={{right:'-1rem'}} whileInView={{right:'4rem'}} transition={transition} className="heart-rate">
          <img src={heart} />
          <span>Heart Rate</span>
          <span><NumberCounter end={116} start={100} delay='4'/> bpm</span>
        </motion.div>
        <img src={hero_image} className="hero-image" />
        <motion.img initial={{right:'8rem'}} whileInView={{right:'24rem'}} transition={{transition}} src={hero_image_back} className="hero-image-back" />
      <motion.div initial={{right:'37rem'}} whileInView={{right:'50rem'}} transition={transition} className="calories">
        <img src={Calories}  />
        <div> 
          <span>Calories Burned</span>
          <span><NumberCounter end={220} start={200} delay='4' preFix="+"/></span>
        </div>
      </motion.div>
      
       
      
      
      </div>
    </div>
  );
}
