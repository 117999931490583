import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Join.css';
export default function Join() {
    const form=useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_5o9mfkq', 'template_a344gqn', form.current, 'e0GcyVptzJRyny7v8')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className="join" id="join">
        <div className="left-j">
            <hr />
            <div>
                <span>READY TO </span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY </span>
                <span>WITH US?</span>
            </div>
        </div>
        <div className="right-j">
            <form action="" ref={form} onSubmit={sendEmail} className="email-container">
                <input type="email" name="user_email" id="" placeholder='Enter You Email Address' />
                <button type='submit' className='btn btn-j'>Join Now</button>
            </form>
        </div>
    </div>
  );
}
