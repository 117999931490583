import React,{useState} from 'react';
import './Header.css';
import {Link} from 'react-scroll';
import Bars from '../../assets/bars.png';
export default function Header() {
  const mobile=window.innerWidth<=768 ? true : false
  const [menu,setmenu]=useState(false);
  return (
    <div className='header' id='header'>
      <h3 className='header--logo'>The Fit Club</h3>
      {menu===false && mobile===true?
      (<div onClick={()=> setmenu(true)} style={{top:'3rem',backgroundColor:'var(--appcolor)',padding:'0.5rem',borderRadius:'5px'}}><img src={Bars} style={{width:'1.5rem',height:'1.5rem'}} alt="" /></div>):
      (<ul className='header--menu'>
        <li className='header--list'><Link activeClass='active' onClick={()=> setmenu(false)} to='header' span={true} smooth={true}>Home</Link></li>
        <li className='header--list'><Link onClick={()=> setmenu(false)} to='program' span={true} smooth={true}>Programs</Link></li>
        <li className='header--list'><Link onClick={()=> setmenu(false)} to='reason' span={true} smooth={true}>Why Us</Link></li>
        <li className='header--list'><Link onClick={()=> setmenu(false)} to='plan' span={true} smooth={true}>Plans</Link></li>
        <li className='header--list'><Link onClick={()=> setmenu(false)} >Testinomials</Link></li>
      </ul>)}
    </div>
  );
}
 